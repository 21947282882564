import {
  Options,
  Splide,
  SplideSlide,
  SplideTrack
} from '@splidejs/react-splide'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { FC, useCallback, useRef, useState } from 'react'
import { Parallax } from 'react-scroll-parallax'

import Button from '@/components/Button'
import Icon from '@/components/Icon'
import Link from '@/components/Link'
import ScrollTableProps from '@/components/ScrollTable/ScrollTableProps'
import Tag from '@/components/Tag'
import Tooltip from '@/components/Tooltip'

import useMediaQuery from '@/hooks/useMediaQuery'

import styles from './ScrollTable.module.scss'
import { routes } from '@/config'

const OPTIONS: Options = {
  autoHeight: true,

  pagination: false,
  mediaQuery: 'min',
  isNavigation: false,
  arrows: true,
  breakpoints: {
    320: {
      fixedWidth: 125,
      gap: 6,
      perPage: 2,
      perMove: 2,
      padding: {
        right: 30
      }
    },
    640: {
      fixedWidth: 200,
      perPage: 1,
      perMove: 1,
      gap: 15
    },

    960: {
      perPage: 3,
      perMove: 3
    },

    1024: {
      fixedWidth: 214,
      perPage: 3,
      perMove: 3
    },

    1366: {
      fixedWidth: 224,
      gap: 20,
      perPage: 3,
      perMove: 3
    },

    1680: {
      fixedWidth: 270
    }
  }
}

const ScrollTable: FC<ScrollTableProps> = ({
  className = '',
  data: {
    dataHeading: captions,
    dataItems: items,
    heading,
    networks,
    withoutCommission,
    more
  }
}) => {
  const isBigScreen = useMediaQuery('(min-width: 1680px)')
  const splideRef = useRef(null)
  const splideNextRef = useRef(null)
  const splidePrevRef = useRef(null)
  const { t } = useTranslation()
  const [prevButtonState, setPrevButtonState] = useState(true)
  const [nextButtonState, setNextButtonState] = useState(false)

  const [activeRowIndex, setActiveRowIndex] = useState(-1)

  const handleClickPrev = useCallback(() => {
    // @ts-ignore
    splideRef.current.go('<')
  }, [splideRef])

  const handleClickNext = useCallback(() => {
    // @ts-ignore
    splideRef.current.go('>')
  }, [splideRef])

  const handleMoved = useCallback(
    // @ts-ignore
    () => {
      // @ts-ignore
      setPrevButtonState(splidePrevRef.current.disabled)
      // @ts-ignore
      setNextButtonState(splideNextRef.current.disabled)
    },
    []
  )

  return (
    <div className={clsx(styles['scroll-table'], className)}>
      <div className={styles['scroll-table__table']}>
        <div className={styles['scroll-table__head-column']}>
          <div className={styles['scroll-table__cell']}></div>
          <div className={styles['scroll-table__cell']}></div>

          <div
            className={styles['scroll-table__cell']}
            dangerouslySetInnerHTML={{ __html: heading }}
          />

          {captions.map(({ title }, index) => (
            <div
              key={title}
              className={clsx(styles['scroll-table__cell'], {
                [styles['scroll-table__cell_hover']]: index === activeRowIndex
              })}
              dangerouslySetInnerHTML={{
                __html: title
              }}
              onMouseEnter={() => setActiveRowIndex(index)}
              onMouseLeave={() => setActiveRowIndex(-1)}
            />
          ))}

          <div className={styles['scroll-table__cell']} />
        </div>
        <div className={styles['scroll-table__splide-column']}>
          <Parallax speed={-15} translateX={[3, -3]} translateY={[0, 0]}>
            <Splide
              onArrowsUpdated={handleMoved}
              ref={splideRef}
              hasTrack={false}
              options={OPTIONS}
              className={styles['scroll-table__splide']}
            >
              <div
                className={clsx(
                  'splide__arrows',
                  styles['scroll-table__splide-arrows']
                )}
              >
                <button
                  ref={splidePrevRef}
                  className="splide__arrow splide__arrow--prev"
                />
                <button
                  ref={splideNextRef}
                  className="splide__arrow splide__arrow--next"
                />
              </div>

              <SplideTrack>
                {items.map(
                  ({ id, icon, commission, data, info = null }, index) => (
                    <SplideSlide
                      role={'section'}
                      data-index={index % 2}
                      key={id + index}
                      className={styles['scroll-table__column']}
                    >
                      <div className={styles['scroll-table__cell']}>
                        <div className={styles['scroll-table__logo']}>
                          {icon && (
                            <Icon
                              className={styles['scroll-table__logo-img']}
                              name={`adv-networks/${icon}`}
                            />
                          )}
                        </div>
                      </div>
                      <div className={styles['scroll-table__cell']}>
                        {networks[id]}
                      </div>
                      <div className={styles['scroll-table__cell']}>
                        {commission === 0 ? (
                          <Tag
                            className={styles['scroll-table__tag']}
                            before={
                              <Icon
                                name={`emoji/fire/${isBigScreen ? 24 : 16}`}
                              />
                            }
                            name={withoutCommission}
                          />
                        ) : (
                          commission
                        )}
                      </div>
                      {Object.entries(data).map(([key, value], index) => (
                        <div
                          key={key}
                          className={clsx(styles['scroll-table__cell'], {
                            [styles['scroll-table__cell_hover']]:
                              index === activeRowIndex
                          })}
                          onMouseEnter={() => setActiveRowIndex(index)}
                          onMouseLeave={() => setActiveRowIndex(-1)}
                        >
                          <div
                            className={clsx(
                              styles['scroll-table__value-unit'],
                              {
                                [styles['scroll-table__value-unit_with-info']]:
                                  info
                              }
                            )}
                          >
                            <div
                              className={styles['scroll-table__value']}
                              dangerouslySetInnerHTML={{
                                __html: value as string
                              }}
                            />
                            <div className={styles['scroll-table__unit']}>
                              %
                            </div>
                            {info && (
                              <Tooltip
                                summary={info}
                                className={styles['scroll-table__info']}
                              >
                                <Icon
                                  name={'status/info/16'}
                                  color={'#5E5E5E'}
                                />
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      ))}
                      <div className={styles['scroll-table__cell']}>
                        <Button
                          title={t('forms:buttons.more') as string}
                          size={'s'}
                          className={styles['scroll-table__button']}
                          shape={'circle'}
                          as={Link}
                          href={routes.USER_AGREEMENTS}
                          after={
                            <Icon
                              name={`arrows/right/${isBigScreen ? 24 : 16}`}
                            />
                          }
                        >
                          {more}
                        </Button>
                      </div>
                    </SplideSlide>
                  )
                )}
              </SplideTrack>
            </Splide>
          </Parallax>
        </div>
      </div>
      <div className={styles['scroll-table__arrows']}>
        <button
          onClick={handleClickPrev}
          className={clsx(
            styles['scroll-table__arrow'],
            styles['scroll-table__arrow_prev']
          )}
          type="button"
          disabled={prevButtonState}
        >
          <Icon name={'arrows/left/32'} color={'#fff'} />
        </button>
        <button
          onClick={handleClickNext}
          className={clsx(
            styles['scroll-table__arrow'],
            styles['scroll-table__arrow_next']
          )}
          type="button"
          disabled={nextButtonState}
        >
          <Icon name={'arrows/right/32'} color={'#fff'} />
        </button>
      </div>
    </div>
  )
}
export default ScrollTable
