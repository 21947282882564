import clsx from 'clsx'
import { FC, ReactNode } from 'react'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Tag.module.scss'

interface TagProps extends DefaultComponentProps {
  before: ReactNode
  name: string
}

const Tag: FC<TagProps> = ({ className = '', before = null, name = '' }) => {
  return (
    <div className={clsx(styles['tag'], className)}>
      {before}
      <div
        className={styles['tag__name']}
        dangerouslySetInnerHTML={{ __html: name }}
      />
    </div>
  )
}
export default Tag
