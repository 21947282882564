import { useCallback, useEffect, useState } from 'react'

import { useDebounce } from '@/hooks/useDebounce'

interface StateProps {
  x: null | number
  y: null | number
}

export function useWindowScroll() {
  const [state, setState] = useState<StateProps>({
    x: null,
    y: null
  })

  const debouncedState = useDebounce(state)

  const scrollTo = useCallback((...args: string[] | object[]) => {
    if (typeof args[0] === 'object') {
      window.scrollTo(args[0])
    } else if (typeof args[0] === 'number' && typeof args[1] === 'number') {
      window.scrollTo(args[0], args[1])
    } else {
      throw new Error(
        `Invalid arguments passed to scrollTo. See here for more info. https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo`
      )
    }
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      const { scrollX = 0, scrollY = 0 } = window
      setState({ x: scrollX, y: scrollY })
    }

    handleScroll()
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return [debouncedState, scrollTo]
}
