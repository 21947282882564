import clsx from 'clsx'
import { FC } from 'react'

import Heading from '@/components/Heading'
import LogoIcon from '@/components/LogoIcon'
import ScrollTable from '@/components/ScrollTable'
import { DataProp } from '@/components/ScrollTable/ScrollTableProps'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Section.module.scss'

interface ProgramProps extends DefaultComponentProps {
  heading: string
  summary: string
  table: DataProp
  notice: string
  id?: string
}

export const Program: FC<ProgramProps> = ({
  className = '',
  heading = '',
  summary = '',
  notice = '',
  table,
  ...rest
}) => (
  <section {...rest} className={clsx(styles['section'], className)}>
    <div className={styles['section__inner']}>
      <LogoIcon light className={styles['section__logo-icon']} />

      <div className={styles['section__content']}>
        <Heading className={styles['section__heading']} level={2}>
          {heading}
        </Heading>
        <div
          className={styles['section__summary']}
          dangerouslySetInnerHTML={{ __html: summary }}
        />
        <div className={styles['section__scroll-table']}>
          <ScrollTable data={table} />
        </div>
        <div
          className={styles['section__notice']}
          dangerouslySetInnerHTML={{ __html: notice }}
        />
      </div>
    </div>
  </section>
)
