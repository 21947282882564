import { useEffect, useState } from 'react'

import { useDebounce } from '@/hooks/useDebounce'

interface SizeProps {
  width: null | number
  height: null | number
}

export function useWindowSize() {
  const [size, setSize] = useState<SizeProps>({
    width: null,
    height: null
  })

  const debouncedSize = useDebounce(size)

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth = 0, innerHeight = 0 } = window
      setSize({
        width: innerWidth,
        height: innerHeight
      })
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return debouncedSize
}
